import React from 'react';

import { IndexRoute, Redirect, Route } from 'react-router';

import LandingContainer from 'common/containers/LandingContainer';
import ReferrerContainer from 'common/containers/ReferrerContainer';
import ExternalRedirect from 'common/core/ExternalRedirect';
import LandingWrapper from 'common/LandingWrapper';
import devURL from 'common/util/devURL';
import { createDynamicImport } from 'common/util/dynamicImport';

export default (importTracker) => {
  const dynamicImport = createDynamicImport(importTracker, 'landing');

  return (
    <Route>
      <Route path="/auth" getComponent={dynamicImport('redirect/AuthRedirect')} />

      <Redirect from="/about.html" to="/about" />
      <Redirect from="/apps" to="/integrations" />
      <Redirect from="/apps/slack" to="/integrations/slack" />
      <Redirect from="/changelog" to="/features/product-changelog" />
      <Redirect from="/feature-request-tracking" to="/use-cases/feature-request-management" />
      <Redirect from="/features/feedback" to="/features/collect-feedback" />
      <Redirect from="/features/changelog" to="/features/product-changelog" />
      <Redirect from="/features/roadmap" to="/features/product-roadmap" />
      <Redirect from="/integrations/azure-active-directory" to="/integrations/microsoft-entra-id" />
      <Redirect from="/integrations/gsuite" to="integrations/google-workspace" />
      <Redirect from="/idea-management" to="/use-cases/idea-management" />
      <Redirect from="/index.html" to="/" />
      <Redirect from="/public-roadmap" to="/use-cases/public-roadmap" />
      <Redirect from="/signin" to="/login" />
      <Redirect from="/case-studies/bench" to="/case-studies" />
      <Redirect from="/customers" to="/case-studies" />
      <Redirect from="/customers/bench" to="/case-studies" />
      <Redirect from="/customers/ahrefs" to="/case-studies/ahrefs" />
      <Redirect from="/customers/clickup" to="/case-studies/clickup" />
      <Redirect from="/customers/circleci" to="/case-studies/circleci" />
      <Redirect from="/customers/readme" to="/case-studies/readme" />
      <Redirect path="/pm-chats" to="/product-manager-chats" />
      <Redirect
        from="/use-cases/feature-request-tracking"
        to="/use-cases/feature-request-management"
      />
      <Redirect from="/use-cases/sales" to="/use-cases/sales-documentation" />

      <Redirect from="/aha" to="/compare/aha" />
      <Redirect from="/trello" to="/compare/trello" />
      <Redirect from="/uservoice" to="/compare/uservoice" />

      {/* Redirect old docs links to developers.canny.io */}
      <ExternalRedirect from="/docs" to={devURL('https://developers.canny.io')} />
      <ExternalRedirect from="/docs/api" to={devURL('https://developers.canny.io/api-reference')} />
      <ExternalRedirect from="/docs/install" to={devURL('https://developers.canny.io/install')} />
      <ExternalRedirect
        from="/docs/install/companies"
        to={devURL('https://developers.canny.io/install/companies')}
      />
      <ExternalRedirect
        from="/docs/install/widget"
        to={devURL('https://developers.canny.io/install/widget')}
      />
      <ExternalRedirect
        from="/docs/install/widget/mobile"
        to={devURL('https://developers.canny.io/install/widget/mobile')}
      />
      <ExternalRedirect
        from="/docs/install/widget/sso"
        to={devURL('https://developers.canny.io/install/widget/sso')}
      />
      <ExternalRedirect
        from="/docs/install/widget/web"
        to={devURL('https://developers.canny.io/install/widget/web')}
      />
      <ExternalRedirect
        from="/docs/mobile"
        to={devURL('https://developers.canny.io/widget/mobile')}
      />
      <ExternalRedirect from="/docs/sso" to={devURL('https://developers.canny.io/widget/sso')} />
      <ExternalRedirect from="/docs/web" to={devURL('https://developers.canny.io/widget/web')} />
      <ExternalRedirect
        from="/docs/widget/mobile"
        to={devURL('https://developers.canny.io/install/widget/mobile')}
      />
      <ExternalRedirect
        from="/docs/widget/sso"
        to={devURL('https://developers.canny.io/install/widget/sso')}
      />
      <ExternalRedirect
        from="/docs/widget/web"
        to={devURL('https://developers.canny.io/install/widget/web')}
      />

      <Route path="/" component={LandingContainer}>
        <Route component={LandingWrapper}>
          <IndexRoute getComponent={dynamicImport('core/LandingV3')} />

          <Route path="/case-studies">
            <IndexRoute getComponent={dynamicImport('caseStudies/CaseStudiesLanding')} />
            <Route
              path="agencyanalytics"
              getComponent={dynamicImport('caseStudies/AgencyAnalyticsLanding')}
            />
            <Route path="ahrefs" getComponent={dynamicImport('caseStudies/AhrefsLanding')} />
            <Route path="akiflow" getComponent={dynamicImport('caseStudies/AkiflowLanding')} />
            <Route path="aryeo" getComponent={dynamicImport('caseStudies/AryeoLanding')} />
            <Route path="axios" getComponent={dynamicImport('caseStudies/AxiosLanding')} />
            <Route path="circleci" getComponent={dynamicImport('caseStudies/CCILanding')} />
            <Route path="clickup" getComponent={dynamicImport('caseStudies/ClickupLanding')} />
            <Route
              path="document360"
              getComponent={dynamicImport('caseStudies/Document360Landing')}
            />
            <Route path="figured" getComponent={dynamicImport('caseStudies/FiguredLanding')} />
            <Route
              path="givebutter"
              getComponent={dynamicImport('caseStudies/GiveButterLanding')}
            />
            <Route path="hive" getComponent={dynamicImport('caseStudies/HiveLanding')} />
            <Route
              path="janetechnologies"
              getComponent={dynamicImport('caseStudies/JaneTechnologiesLanding')}
            />
            <Route path="knak" getComponent={dynamicImport('caseStudies/KnakLanding')} />
            <Route path="missive" getComponent={dynamicImport('caseStudies/MissiveLanding')} />
            <Route path="mercury" getComponent={dynamicImport('caseStudies/MercuryLanding')} />
            <Route path="tldv" getComponent={dynamicImport('caseStudies/TLDVLanding')} />
            <Route path="pallyy" getComponent={dynamicImport('caseStudies/PallyyLanding')} />
            <Route path="outlier" getComponent={dynamicImport('caseStudies/OutlierLanding')} />
            <Route path="readme" getComponent={dynamicImport('caseStudies/ReadmeLanding')} />
            <Route path="spekit" getComponent={dynamicImport('caseStudies/SpekitLanding')} />
            <Route path="appcues" getComponent={dynamicImport('caseStudies/AppcuesLanding')} />
            <Route path="getimgai" getComponent={dynamicImport('caseStudies/GetimgAILanding')} />
            <Route
              path="stickermule"
              getComponent={dynamicImport('caseStudies/StickerMuleLanding')}
            />
            <Route path="strapi" getComponent={dynamicImport('caseStudies/StrapiLanding')} />
          </Route>

          <Route path="/compare">
            <IndexRoute getComponent={dynamicImport('compare/CompareLanding')} />
            <Route path="aha" getComponent={dynamicImport('compare/LandingAha')} />
            <Route
              path="productboard"
              getComponent={dynamicImport('compare/LandingProductboard')}
            />
            <Route path="trello" getComponent={dynamicImport('compare/LandingTrello')} />
            <Route path="uservoice" getComponent={dynamicImport('compare/LandingUserVoice')} />
            <Route path="pendo" getComponent={dynamicImport('compare/LandingPendo')} />
          </Route>

          <Route path="/features">
            <IndexRoute getComponent={dynamicImport('features/LandingFeaturesPage')} />
            <Route
              path="analyze-feedback"
              getComponent={dynamicImport('features/LandingAnalyzeFeedbackPage')}
            />
            <Route
              path="collect-feedback"
              getComponent={dynamicImport('features/LandingFeedbackPage')}
            />
            <Route path="autopilot" getComponent={dynamicImport('features/LandingAutopilotPage')} />
            <Route
              path="product-changelog"
              getComponent={dynamicImport('features/LandingChangelogPage')}
            />
            <Route
              path="product-roadmap"
              getComponent={dynamicImport('features/LandingRoadmapPage')}
            />
          </Route>

          <Route path="/integrations">
            <IndexRoute getComponent={dynamicImport('integrations/IntegrationsLanding')} />
            <Route path="discord" getComponent={dynamicImport('integrations/DiscordLanding')} />
            <Route
              path="google-analytics"
              getComponent={dynamicImport('integrations/GoogleAnalyticsLanding')}
            />
            <Route
              path="chrome"
              getComponent={dynamicImport('integrations/ChromeExtensionLanding')}
            />
            <Route
              path="openid"
              getComponent={dynamicImport('integrations/OpenIDConnectSSOLanding')}
            />
            <Route
              path="microsoft-entra-id"
              getComponent={dynamicImport('integrations/MicrosoftEntraIDLanding')}
            />
            <Route path="okta" getComponent={dynamicImport('integrations/OktaSSOLanding')} />
            <Route path="onelogin" getComponent={dynamicImport('integrations/OneloginLanding')} />
            <Route path="asana" getComponent={dynamicImport('integrations/AsanaLanding')} />
            <Route
              path="google-workspace"
              getComponent={dynamicImport('integrations/GoogleWorkspaceLanding')}
            />
            <Route
              path="clickup"
              getComponent={dynamicImport('integrations/ClickUpIntegrationLanding')}
            />
            <Route path="devops" getComponent={dynamicImport('integrations/DevOpsLanding')} />
            <Route path="github" getComponent={dynamicImport('integrations/GitHubLanding')} />
            <Route path="hubspot" getComponent={dynamicImport('integrations/HubSpotLanding')} />
            <Route path="intercom" getComponent={dynamicImport('integrations/IntercomLanding')} />
            <Route path="jira" getComponent={dynamicImport('integrations/JiraLanding')} />
            <Route
              path="salesforce"
              getComponent={dynamicImport('integrations/SalesforceLanding')}
            />
            <Route path="segment" getComponent={dynamicImport('integrations/SegmentLanding')} />
            <Route path="slack" getComponent={dynamicImport('integrations/SlackLanding')} />
            <Route path="zendesk" getComponent={dynamicImport('integrations/ZendeskLanding')} />
          </Route>

          <Route path="/use-cases">
            <IndexRoute getComponent={dynamicImport('useCases/LandingUseCases')} />
            <Route
              path="feature-request-management"
              getComponent={dynamicImport('useCases/LandingFeatureRequestManagement')}
            />
            <Route
              path="idea-management"
              getComponent={dynamicImport('useCases/LandingIdeaManagement')}
            />
            <Route
              path="internal-feedback"
              getComponent={dynamicImport('useCases/LandingInternalFeedback')}
            />
            <Route
              path="product-management"
              getComponent={dynamicImport('useCases/LandingProductManagement')}
            />
            <Route
              path="public-roadmap"
              getComponent={dynamicImport('useCases/LandingPublicRoadmap')}
            />
            <Route
              path="sales-documentation"
              getComponent={dynamicImport('useCases/LandingSalesDocumentation')}
            />
            <Route
              path="customer-feedback"
              getComponent={dynamicImport('useCases/LandingCustomerFeedback')}
            />
            <Route
              path="enterprise-feedback-management"
              getComponent={dynamicImport('useCases/LandingEnterpriseFeedbackManagement')}
            />
            <Route
              path="bug-tracking-tool"
              getComponent={dynamicImport('useCases/LandingBugTracking')}
            />
          </Route>

          <Route path="/about" getComponent={dynamicImport('core/LandingAboutPage')} />
          <Route path="/assessment" getComponent={dynamicImport('core/LandingAssessmentPage')} />
          <Route
            path="/powered-by-canny"
            getComponent={dynamicImport('core/LandingPoweredByCanny')}
          />
          <Route path="/pricing" getComponent={dynamicImport('core/LandingPricingPage')} />
          <Route
            path="/product-manager-chats"
            getComponent={dynamicImport('core/LandingPMChats')}
          />
          <Route path="/request-demo">
            <IndexRoute getComponent={dynamicImport('core/LandingRequestDemo')} />
          </Route>
        </Route>

        <Route path="/asana-redirect" getComponent={dynamicImport('redirect/AsanaRedirect')} />
        <Route path="/azure-redirect" getComponent={dynamicImport('redirect/AzureRedirect')} />
        <Route path="/billing" getComponent={dynamicImport('redirect/BillingRedirect')} />
        <Route
          path="/azure-devops-redirect"
          getComponent={dynamicImport('redirect/AzureDevopsRedirect')}
        />
        <Route path="/discord" getComponent={dynamicImport('redirect/DiscordRedirect')} />
        <Route path="/gong-redirect" getComponent={dynamicImport('redirect/GongRedirect')} />
        <Route
          path="/helpscout-redirect"
          getComponent={dynamicImport('redirect/HelpscoutRedirect')}
        />
        <Route path="/install-clickup" getComponent={dynamicImport('redirect/ClickupInstall')} />
        <Route path="/install-github" getComponent={dynamicImport('redirect/GitHubInstall')} />
        <Route path="/install-hubspot" getComponent={dynamicImport('redirect/HubspotInstall')} />
        <Route path="/install-intercom" getComponent={dynamicImport('redirect/IntercomInstall')} />
        <Route
          path="/install-teams"
          getComponent={dynamicImport('redirect/MicrosoftTeamsInstall')}
        />
        <Route path="/intercom" getComponent={dynamicImport('redirect/IntercomRedirect')} />
        <Route path="/linear-redirect" getComponent={dynamicImport('redirect/LinearRedirect')} />
        <Route path="/oidc-redirect" getComponent={dynamicImport('redirect/OIDCRedirect')} />
        <Route path="/okta-redirect" getComponent={dynamicImport('redirect/OktaRedirect')} />
        <Route
          path="/onelogin-redirect"
          getComponent={dynamicImport('redirect/OneLoginRedirect')}
        />
        <Route path="/redirect" getComponent={dynamicImport('redirect/AdminRedirect')} />
        <Route path="/salesforce" getComponent={dynamicImport('redirect/SalesforceRedirect')} />
        <Route path="/segment" getComponent={dynamicImport('redirect/SegmentRedirect')} />
        <Route path="/slack" getComponent={dynamicImport('redirect/SlackRedirect')} />
        <Route path="/zendesk" getComponent={dynamicImport('redirect/ZendeskRedirect')} />
        <Route path="/zoom-redirect" getComponent={dynamicImport('redirect/ZoomRedirect')} />

        <Route getComponent={dynamicImport('core/RegistrationWrapper')}>
          <Route component={ReferrerContainer}>
            <Route path="/register">
              <IndexRoute getComponent={dynamicImport('core/Register')} />
              <Route path="*" getComponent={dynamicImport('core/Register')} />
            </Route>
          </Route>
        </Route>

        <Route getComponent={dynamicImport('core/ContentWrapper')}>
          <Route path="/accept-invite" getComponent={dynamicImport('core/LandingAcceptInvite')} />
          <Route path="/login" getComponent={dynamicImport('core/SignIn')} />
          <Route path="/previewEmail" getComponent={dynamicImport('core/PreviewEmail')} />
          <Route path="/privacy" getComponent={dynamicImport('core/PrivacyPolicy')} />
          <Route path="/referral-terms" getComponent={dynamicImport('core/ReferralTerms')} />
          <Route path="/resetPassword" getComponent={dynamicImport('core/ResetPassword')} />
          <Route path="/security" getComponent={dynamicImport('core/Security')} />
          <Route path="/terms" getComponent={dynamicImport('core/TermsOfService')} />
          <Route path="*" getComponent={dynamicImport('FourOhFour', { app: 'general' })} />
        </Route>
      </Route>
    </Route>
  );
};
