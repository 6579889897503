import React, { useRef } from 'react';

import classnames from 'classnames';
import { ChevronDown } from 'lucide-react';

import 'css/components/www/LandingV3/_LandingNavLinks.scss';

import Link from 'common/Link';

import type { Nav } from 'common/www/LandingV3/LandingNav/LandingNav';

type Props = {
  nav: Nav;
};

const LandingNavLinks = ({ nav }: Props) => {
  const navRef = useRef<null | HTMLElement>(null);
  const focusNav = () => {
    if (navRef.current) {
      navRef.current.focus();
    }
  };

  return (
    <nav className="LandingNavLinks" ref={navRef} tabIndex={-1}>
      <ul className="LandingNavLinks__container">
        {Object.entries(nav).map(([heading, navItems], headingIndex) => {
          if (typeof navItems === 'string') {
            return (
              <li className="LandingNavLinks__item" key={headingIndex}>
                <Link to={navItems} className="LandingNavLinks__link">
                  <span className="LandingNavLinks__heading">{heading}</span>
                </Link>
              </li>
            );
          }
          return (
            <li
              className="LandingNavLinks__item LandingNavLinks__item--dropdown"
              key={headingIndex}>
              <button className="LandingNavLinks__dropdown-trigger">
                <span className="LandingNavLinks__heading">
                  {heading} <ChevronDown aria-hidden size={18} strokeWidth={1.5} />
                </span>
              </button>
              {navItems.length && (
                <ul className="LandingNavLinks__dropdown-container">
                  {navItems.map(({ copy, link, heading, external }, index) => {
                    return (
                      <li
                        className={classnames('LandingNavLinks__dropdown-item', {
                          'LandingNavLinks__dropdown-item--heading': heading,
                        })}
                        key={index}>
                        {!link && <p className="LandingNavLinks__dropdown-copy">{copy}</p>}
                        {link && (
                          <>
                            {external ? (
                              <a
                                className="LandingNavLinks__dropdown-copy LandingNavLinks__dropdown-copy--link"
                                href={link}
                                target="_blank"
                                rel="noopener">
                                {copy}
                              </a>
                            ) : (
                              <Link
                                to={link}
                                className="LandingNavLinks__dropdown-copy LandingNavLinks__dropdown-copy--link"
                                onTap={focusNav}>
                                {copy}
                              </Link>
                            )}
                          </>
                        )}
                      </li>
                    );
                  })}
                </ul>
              )}
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

export default LandingNavLinks;
