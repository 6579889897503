import React, { useState } from 'react';

import classnames from 'classnames';

import 'css/components/www/LandingV3/_LandingNavMobile.scss';

import Link from 'common/Link';

import type { NavItem } from 'common/www/LandingV3/LandingNav/LandingNav';

type Props = {
  mobileNavItems: NavItem[];
};

const LandingNavMobile = ({ mobileNavItems }: Props) => {
  const [menuVisible, setMenuVisible] = useState(false);

  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };

  return (
    <nav className={classnames('LandingNavMobile', { 'LandingNavMobile--open': menuVisible })}>
      <button
        className="LandingNavMobile__toggle-container"
        onClick={toggleMenu}
        aria-label={`${menuVisible ? 'Closes' : 'Opens'} the mobile navigation menu`}>
        <span className="LandingNavMobile__toggle-bar"></span>
        <span className="LandingNavMobile__toggle-bar"></span>
        <span className="LandingNavMobile__toggle-bar"></span>
      </button>
      <ul className="LandingNavMobile__menu-container">
        {mobileNavItems.map((menuItem, index) => {
          return (
            <li className="LandingNavMobile__menu-item" key={index}>
              {menuItem.external && (
                <a href={menuItem.link} className="LandingNavMobile__menu-link">
                  <span className="LandingNavMobile__menu-copy">{menuItem.copy}</span>
                </a>
              )}
              {!menuItem.external && (
                <Link
                  to={menuItem.link}
                  className="LandingNavMobile__menu-link"
                  activeClassName="LandingNavMobile__menu-link--active"
                  onTap={() => setMenuVisible(false)}>
                  <span className="LandingNavMobile__menu-copy">{menuItem.copy}</span>
                </Link>
              )}
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

export default LandingNavMobile;
