import React, { Component } from 'react';

import { hot } from 'react-hot-loader/root';

import CannyRouter from 'common/core/CannyRouter';

import FBPixel from './3rd/FBPixel';
import RedditPixel from './3rd/RedditPixel';
import CannyRoutes from './CannyRoutes';

import 'css/Canny.scss';

FBPixel.init();
RedditPixel.init();

class CannyApp extends Component {
  render() {
    const { history } = this.props;
    return <CannyRouter history={history} routes={CannyRoutes()} />;
  }
}

export default hot(CannyApp);
